import React, {
    useContext, useEffect, useMemo, useState
} from 'react';
import {
    Div, P, zendoStorage
} from '../../hybrid/wrappers';
import getStyles from '../../project/styles/widget-styles/region-and-currency-switcher';
import Styles from '../../common/decorators/Styles';
import UserContext from '../../common/utils/getContext';
import Modal from './regionAndCurrencySwitcherModal';
import ReactCountryFlag from 'react-country-flag';
import { Skeleton } from '../plug/Skeleton';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import RegionAndCurrencySwitcherConfirm from './regionAndCurrencySwitcherConfirm';
import { getData } from '../../common/utils/router';

const RegionAndCurrencySwitcher = ({
    styles, mode, hideCountryAndCurrency, setShowLoader = false
}) => {
    const {
        localization,
        lang,
        activeLangs,
        userToken,
        setState
    } = useContext(UserContext);


    const router = useRouter()

    const activeLanguage = useMemo(() => activeLangs && (activeLangs?.find((language) => language.alias === lang) || activeLangs?.find((language) => language.isDefault) || null), [activeLangs, lang])

    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const [isShowPortalModal, setIsShowPortalModal] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);

    useEffect(() => {
        if (localization.country?.id) {
            if (!zendoStorage.get('localization')) {
                setIsShowConfirmModal(true)
            }
        }
    }, [lang, localization, localization?.country?.id]);

    const redirectToDomain = async (newLocalization) => {
        if (setShowLoader){
            setShowLoader(true)
        } else {
            setIsRedirecting(true)
        }
        const shopsData = await getData('/api/v1/shop/shop', userToken, lang);

        const findShop = shopsData?.data?.find(el => el.country.geoname_id === newLocalization.country.geoname_id || el.country.geoname_id === newLocalization.country.id)
        const { pathname, searchParams } = new URL(window.location.href?.includes('store') ? `${process.env.NEXT_PUBLIC_SERVER_FRONT_URL}/${lang}/store`: window.location.href)
        searchParams.delete('hash')
        searchParams.delete('kzState')
        searchParams.delete('country_id')
        searchParams.delete('country')
        if (findShop && findShop?.domain?.name && findShop.status !== 'hidden'){
            const hashData = await getData('/api/v1/get-hash', userToken, lang);
            if (hashData.hash){
                searchParams.set('hash', hashData?.hash);
            }
            searchParams.toString()

            const langPrefixRegex = new RegExp(`^\\/${lang}\\/`);

            if (router.asPath.length > 2) {
                window.location.href = `${findShop.domain.name}${pathname}${!!searchParams.size ? `?${searchParams}` : ''}`?.replace(langPrefixRegex, `/${findShop.locale}/`)
            } else {
                window.location.href = `${findShop.domain.name}${pathname}${findShop.locale}${!!searchParams.size ? `?${searchParams}` : ''}`;
            }
        } else if (process.env.NEXT_PUBLIC_SERVER_FRONT_URL !== process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL && newLocalization.country.code == 'KZ'){
            const hashData = await getData('/api/v1/get-hash', userToken, lang);
            if (hashData.hash){
                searchParams.set('hash', hashData?.hash);
            }
            searchParams.set('kzState', 'true')
            searchParams.toString()
            window.location.href = `${process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL}${pathname === '/'? `/${newLocalization?.language? newLocalization?.language: lang ? lang : ''}`: pathname}${!!searchParams.size ? `?${searchParams}` : ''}`
        } else if (process.env.NEXT_PUBLIC_SERVER_FRONT_URL !== process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL){
            const hashData = await getData('/api/v1/get-hash', userToken, lang);
            if (hashData.hash){
                searchParams.set('hash', hashData?.hash);
            }
            searchParams.set('country', newLocalization.country.code)
            searchParams.set('country_id', newLocalization.country.id)
            searchParams.toString()
            window.location.href = `${process.env.NEXT_PUBLIC_MAIN_SERVER_FRONT_URL}${pathname === '/'? `/${newLocalization?.language? newLocalization?.language: lang ? lang : ''}`: pathname}${!!searchParams.size ? `?${searchParams}` : ''}`
        } else {
            setState('localization', newLocalization)
        }
        // }
        if (setShowLoader){
            setShowLoader(false)
        } else {
            setIsRedirecting(false)
        }
    }

    return (
        <Div styles={styles.positionRelative}>
            {
                isRedirecting && <Div styles={styles.loaderWrapper}>
                    <svg className='spinnerCart' viewBox='0 0 50 50'>
                        <circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='5'></circle>
                    </svg>
                </Div>
            }
            <Skeleton
                wrapperStyles={{ width: '10rem' }}
                wait={Boolean(localization?.country?.code)}
                elements={[{
                    mode: 'light',
                    type: 'blockSM',
                    gap: [1, 0, 1, 0]
                }]}
            >
                <Div
                    data-uitest='671_uitest' onClick={() => setIsShowPortalModal(pre => !pre)}
                    effects={{
                        hover: {
                            border: `1px solid ${styles.variable.greyDeepExtraDarkColor}`,
                            color: styles.variable.blueColor,
                            transition: '.3s'
                        }
                    }}
                    styles={mode === 'light' ? styles.regionsLangButtonLight : styles.regionsLangButton}>
                    <Div styles={styles.menuLangMargin}>
                        <Div styles={styles.menuLangsWrapper}>
                            <Div styles={mode === 'light' ? styles.menuLangSwitcherDesktopLight : styles.menuLangSwitcherDesktop}>
                                <Div styles={styles.menuLangSwitcherLangFlag}>
                                    {
                                        activeLanguage && localization?.country?.code ?
                                            <P styles={styles.menuLangSwitcherFlag}>
                                                <ReactCountryFlag svg countryCode={localization?.country?.code} style={{
                                                    objectFit: 'cover', width: '100%', height: '100%'
                                                }} />
                                            </P> :
                                            <P styles={styles.menuLangSwitcherNameDesktop}>
                                                {
                                                    activeLanguage?.alias || lang
                                                }
                                            </P>
                                    }
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                    <Div>
                        <Div
                            styles={{ ...styles.wrapper, ...mode === 'light' ? styles.wrapperLight : {} }}>
                            {activeLanguage?.name}{activeLanguage?.name ? ', ' : ''}{localization.currency}
                        </Div>
                    </Div>
                </Div>
            </Skeleton>
            {isShowPortalModal && <Modal
                setIsShowConfirmModal={setIsShowConfirmModal}
                setIsShowPortalModal={setIsShowPortalModal}
                redirectToDomain={redirectToDomain}
                hideCountryAndCurrency={hideCountryAndCurrency}
            />}

            {
                isShowConfirmModal && <RegionAndCurrencySwitcherConfirm
                    styles={styles}
                    mode={mode}
                    redirectToDomain={redirectToDomain}
                    setIsShowPortalModal={setIsShowPortalModal}
                    setIsShowConfirmModal={setIsShowConfirmModal}
                />
            }
        </Div>
    );
}

RegionAndCurrencySwitcher.propTypes = {
    styles: PropTypes.object,
    mode: PropTypes.oneOf(['light', 'left'])
}
export default Styles(RegionAndCurrencySwitcher, getStyles);